@import 'frontend/styles/functions';

.commentsWrapper {
  width: 336px;
  display: flex;
  flex-direction: column;
  height: calc(100vh - 67px);
  max-height: 100%;
  z-index: 1;
  position: sticky;
  top: 67px;
  background-color: var(--white);
  border-top: 1px solid var(--grayscale15);
  box-shadow:
    0px 2px 1px 0px rgba(0, 0, 0, 0.1),
    0px 24px 24px 0px rgba(0, 0, 0, 0.05);
  @media all and (max-width: 1240px) {
    position: fixed;
    right: 0;
    top: 67px;
  }

  @media all and (max-width: 1024px) {
    height: 100vh;
    top: 0;
    padding-top: 103px;
  }

  & .commentsContainer {
    display: flex;
    flex-direction: column;
    flex: 1;
    & .commentsContainerInfo {
      padding: spacing(0.625) spacing(1.5);
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      color: var(--grayscale60);
      font-size: var(--font-size-small);
      font-weight: var(--font-weight450);
    }
  }
}
