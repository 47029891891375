@import 'frontend/styles/functions';

.loader {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-top: spacing(2);
}

.commentsContent {
  display: flex;
  flex-direction: column;
  gap: spacing(0.5);
  overflow: auto;
  flex: 1 0 0;
  padding-bottom: spacing(14);
  &.commentsContentEmpty {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0 spacing(3);
  }
  & .comment {
    padding: spacing(1) spacing(1) spacing(1) spacing(2);
    display: flex;
    flex-direction: column;
    cursor: pointer;
    &:hover {
      background-color: var(--grayscale0);
    }
    &.activeComment {
      background-color: var(--grayscale10);
      & .commentText,
      & .commentSender {
        color: var(--primary-color);
      }
    }

    &.pinnedComment {
      background-color: var(--yellow-sunglow-light);
    }

    &:hover,
    &.activeDropdownComment {
      & .commentHeading {
        & .commentActions {
          display: flex;
        }
      }
    }

    & .commentHeading {
      display: flex;
      flex-direction: row;
      gap: spacing(1);
      color: var(--text-grayscale70);
      font-size: var(--font-size-small);
      align-items: center;
      line-height: 24px;
      position: relative;
      & .commentPinned {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-self: flex-end;
        justify-content: flex-end;
      }
      & .commentActions {
        display: none;
        align-items: center;
        flex-direction: row;
        justify-content: flex-end;
      }
      & .commentRead {
        position: absolute;
        left: -5px;
        top: -3px;
        border-radius: 50%;
        width: 6px;
        height: 6px;
        background-color: var(--primary-color);
      }
      & .commentDate {
        flex: 1;
      }
      & .commentAvatar {
        width: 16px;
        height: 16px;
        border-radius: 50%;
        overflow: hidden;

        & > img {
          width: 100%;
          object-fit: cover;
        }
      }

      & .commentPage {
        text-decoration: underline;
      }
    }

    & .commentSender {
      margin-top: spacing(0.25);
      color: var(--text-grayscale90);
      font-weight: var(--font-weight-medium);
      font-size: var(--font-size-small);
      line-height: 18px;
    }

    & .commentText {
      color: var(--text-grayscale90);
      font-size: var(--font-size-medium);
      line-height: 20px;
      white-space: pre-line;
    }
  }
}
