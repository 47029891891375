@import 'frontend/styles/variables';
@import 'frontend/styles/functions';

.description {
  margin-bottom: 0;
}

.table {
  margin-top: spacing(3);
}

.sectionWidthLimited {
  max-width: 640px;
  width: 100%;

  > div {
    max-width: 450px;
  }
}

section {
  section {
    &:not(:last-child) {
      margin-bottom: spacing(4);
    }
  }
}
