@import 'frontend/styles/functions';

.commentsNewComment {
  padding: spacing(1) spacing(2) spacing(0.75);
  display: grid;
  grid-template-columns: 1fr auto;
  align-items: flex-end;
  gap: spacing(1.5);
  & .commentsSendIcon {
    margin-bottom: spacing(0.5);
    cursor: pointer;
    &:hover {
      outline: 2px solid rgba(0, 0, 0, 0.04);
      background-color: rgba(0, 0, 0, 0.04);
      border-radius: var(--border-radius-medium);
    }
  }
  & .commentsNewCommentInput {
    border-radius: var(--border-radius-18);
    padding: spacing(0.75) spacing(2);
    max-height: 320px;
  }
}
