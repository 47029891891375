@import 'frontend/styles/functions';

.chatSystemMessage {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-size: var(--font-size-small);
  color: var(--text-grayscale70);
  font-weight: var(--font-weight-normal);
  line-height: 18px;
  position: relative;
  & .systemMessageRow {
    display: flex;
    flex-direction: row;
    align-items: center;
    & .systemMessageActions {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: spacing(0.5);
      & .optionsItem {
        display: flex;
        align-items: center;
      }
    }
  }
  & .chatSystemMessageTimestamp {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: spacing(1.5);
  }
}
