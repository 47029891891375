@import './fonts';
@import './variables';
@import './typography';
@import './utils';
@import './mixins';
@import './functions';
@import './colors';

/* Cascade Layers, from weakest to strongest. Unlayered styles always win. */
@layer kindly-resets, /* general resets styles */
       kindly-components, /* base-component styles */
       kindly-components-overrides, /* base-component overrides styles */
       kindly-overrides; /* global overrides styles */

:root {
  /* Fonts */
  --font-family:
    'IBMPlex', 'Chinese Quote', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'PingFang SC', 'Hiragino Sans GB',
    'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji',
    'Segoe UI Symbol';
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, 'Liberation Mono', 'Courier New', monospace;
  --font-size-small: 12px;
  --font-size-medium: 14px;
  --font-size-large: 16px;
  --font-size-extralarge: 30px;
  --font-size-h1: 24px;
  --font-size-h2: 22px;
  --font-size-h3: 19px;
  --font-size-h4: 16px;
  --font-weight-normal: 400;
  --font-weight-medium: 500;
  --font-weight-450: 450;

  /* Sizes */
  --gutter-small: 15px;
  --gutter-medium: 30px; /* Set gutter base size used for margin & paddings */
  --border-radius-small: 4px;
  --border-radius-medium: 6px;
  --border-radius-large: 8px;
  --border-radius-16: 16px;
  --border-radius-18: 18px;
  --navbar-height: 103px; /* not used in CSS at the moment */

  /* Z-indexes */
  --modal-z-index: 900;
  --navbar-z-index: 200;
  --context-menu-z-index: 20;

  /* Legacy shadows. 
  FIXME: convert remaining usage. */
  --box-shadow: 0 1px 1px 0 var(--shadow-color);
  --box-shadow-hover: 0 2px 6px 0 var(--shadow-color-dark);

  /* Shadows */
  --box-shadow-card: 0px 0px 1px rgba(0, 0, 0, 0.24), 0px 2px 6px rgba(0, 0, 0, 0.04);
  --box-shadow-level1: 0px 0px 1px rgba(0, 0, 0, 0.2), 0px 2px 8px rgba(0, 0, 0, 0.06);
  --box-shadow-level2:
    0px 8px 48px rgba(0, 0, 0, 0.06), 0px 0px 1px rgba(0, 0, 0, 0.16), 0px 4px 8px rgba(0, 0, 0, 0.04);
  --box-shadow-button: 0px 1px 4px 0px rgba(0, 0, 0, 0.08);
  --box-shadow-card-bottom: 0px 1px 2px -2px rgba(0, 0, 0, 0.24), 0px 10px 8px -8px rgba(0, 0, 0, 0.04);

  /* Primary colors */
  --blue-ribbon: #0069ff;
  --black: #000;
  --white: #fff;
  --pink-heliotrope: #da3cff;
  --green-harlequin: #1cd300;

  /* Supporting colors */
  --red-pomegranate: #fa4d1e;
  --blue-malibu: #4fb9f3;
  --yellow-sunglow: #ffca3a;
  --yellow-sunglow-light: #fff1cc;
  --yellow-sunglow-dark: #4d3800;

  /* Triads */
  --triad-pink-light: #ffcce4;
  --triad-pink-medium: #e5006b;
  --triad-pink-dark: #4d0024;
  --triad-purple-light: #f1ccff;
  --triad-purple-medium: #a800e5;
  --triad-purple-dark: #38004d;
  --triad-red-light: #ffd6cc;
  --triad-red-medium: #e52e00;
  --triad-red-dark: #4d0f00;
  --triad-yellow-light: #fff0cc;
  --triad-yellow-medium: #e5a100;
  --triad-yellow-dark: #4d3600;
  --triad-blue-light: #ccdbff;
  --triad-blue-medium: #0045e5;
  --triad-blue-dark: #00174d;
  --triad-teal-light: #ccfff8;
  --triad-teal-medium: #00e5c7;
  --triad-teal-dark: #004d42;
  --triad-green-light: #c7ffb2;
  --triad-green-medium: #3de500;
  --triad-green-dark: #144d00;

  /* Hover, active and light states.
   * - Hover is equivalent to sass function darken($color, 8%)
   * - active to darken($color, 16%)
   * - light to lighten($color, 24%)
   * FIXME: Replace this with CSS4 color-mix function later
   * Ref: https://caniuse.com/mdn-css_types_color_color-mix
  **/
  --blue-ribbon--hover: #0058d6;
  --blue-ribbon--active: #0047ad;
  --blue-ribbon--dark: #001f4d;
  --blue-ribbon--light: #cce1ff;
  --blue-ribbon--very-light: #d6e7ff;
  --red-pomegranate--hover: #ea3605;
  --red-pomegranate--active: #c22d04;
  --pink-heliotrope--light: #f9deff;
  --pink-heliotrope--very-light: #fae3ff;

  /* Supporting gray tones color */
  --grayscale0: #f7fafc;
  --grayscale5: #f4f7fa;
  --grayscale10: #f1f4f8;
  --grayscale15: #edf0f4;
  --grayscale20: #e8ecf1;
  --grayscale30: #dbe0e6;
  --grayscale40: #c8cfd8;
  --grayscale50: #afb8c4;
  --grayscale60: #8f9aaa;
  --grayscale70: #6a778a;
  --grayscale80: #445265;
  --grayscale90: #232f41;
  --grayscale100: #0c141f;

  /* Misc colors with semantic name */
  --primary-color: var(--blue-ribbon);
  --primary-idle: linear-gradient(0deg, rgba(0, 0, 0, 0.3) 0%, rgba(0, 0, 0, 0.3) 100%) var(--primary-color);

  --supportive-color: var(--yellow-sunglow); /* Original: #ffc34a */
  --success-color: var(--green-harlequin); /* Original #50e39b */
  --error-color: var(--red-pomegranate); /* Original: #f6665e */
  --orange-color: #ff8a3a;
  --border-color-base: #e2e2e2;
  --shadow-color: rgba(0, 0, 0, 0.1);
  --shadow-color-dark: rgba(0, 0, 0, 0.15);
  --shadow-border: 0px 2px 6px 0px rgba(0, 0, 0, 0.04), 0px 0px 1px 0px rgba(0, 0, 0, 0.24);

  /* Text colors */
  --text-grayscale90: var(--grayscale90);
  --text-grayscale80: var(--grayscale80);
  --text-grayscale70: var(--grayscale70);
}

body {
  margin: 0;
  padding: 0;
  background-color: var(--grayscale5);
  font-family: var(--font-family);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: var(--font-size-medium);
  color: var(--text-grayscale90);
  font-feature-settings:
    'clig' off,
    'liga' off;
  box-sizing: border-box;

  button,
  datalist,
  fieldset,
  input,
  label,
  legend,
  optgroup,
  option,
  output,
  select,
  textarea {
    font-family: var(--font-family);
  }

  * {
    box-sizing: border-box;

    @include scrollBars;
  }
}

div[tabindex='-1']:focus {
  outline: 0;
}

strong {
  font-weight: var(--font-weight-medium);
}
