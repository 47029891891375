@import 'frontend/styles/functions';

.emptyFeaturePanel {
  height: 100%;
  .emptyFeatureWrapper {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(auto, 432px));
    gap: spacing(5);
    justify-content: center;
    align-content: center;
    height: 100%;

    .emptyFeatureImageContainer {
      max-height: 432px;
      & > img {
        width: 100%;
        object-fit: contain;
      }
    }

    & .emptyFeatureDescriptionContainer {
      align-self: center;
      max-width: 364px;
      & .emptyFeatureTitle {
        font-size: 24px;
        font-weight: var(--font-weight-medium);
        margin-bottom: 6px;
        color: var(--grayscale90);
      }

      & .emtpyProactivtyDescription {
        font-size: (--font-size-medium);
        line-height: 20px;
        color: var(--grayscale70);
        margin-bottom: 10px;
      }
    }
  }
}
