@import 'frontend/styles/functions';

.commentsHeader {
  padding: spacing(2) spacing(2) spacing(1) spacing(1.5);
  gap: spacing(2);
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid var(--grayscale15);
  & .commentsActionRow {
    display: flex;
    flex-direction: row;
    gap: spacing(1);
    align-items: center;
  }
  & .commentsTitleRow {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: spacing(0.5);

    & .commentsTitle {
      font-size: var(--font-size-medium);
      font-weight: var(--font-weight-medium);
      color: var(--text-grayscale90);
      display: flex;
      flex-direction: row;
      align-items: center;
    }

    & .commentsClose {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: spacing(0.5);
      & .commentsShortcutBadge {
        align-self: flex-end;
        background-color: transparent;
        border: 1px solid var(--grayscale10);
        padding: spacing(0.5);
      }
    }
  }
}
