@import 'frontend/styles/variables';
@import 'frontend/styles/functions';
@import 'frontend/styles/mixins';

.container {
  display: grid;
  grid-template-columns: max-content minmax(350px, auto);
  grid-gap: spacing(5);

  @media (max-width: $tablet) {
    grid-template-columns: 1fr;
  }
}

.helptext {
  color: var(--grayscale70);
}

.logoField {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;

  .logo {
    height: 100px;
    min-height: 100px;
    width: 100px;
    margin-right: spacing(2);
    background-size: contain;
    background-repeat: no-repeat;
  }
}

.timezonePicker {
  max-width: 300px;
}

.ssoOptions {
  display: flex;
  flex-direction: column;
}
