@import 'frontend/styles/functions';

.labelsContainer {
  display: flex;
  flex: 1;
  border: none;
  background: none;
  padding: 0;
  align-items: flex-start;
  flex-wrap: wrap;
  gap: spacing(0.5);
  cursor: pointer;
  & .label {
    line-height: 18px;
  }

  .chatStatus {
    color: var(--text-grayscale90);
    font-size: var(--font-size-small);
    font-weight: var(--font-weight-medium);
    padding: spacing(0.5) spacing(1);
    background: var(--grayscale5);
    border-radius: f var(--border-radius-small);
    white-space: nowrap;
  }

  .hiddenLabels {
    color: var(--grayscale70);
    font-size: var(--font-size-small);
    font-weight: var(--font-weight-medium);
    padding: spacing(0.125) spacing(0.5) spacing(0.125) spacing(0.5);
    background: var(--grayscale5);
    border-radius: var(--border-radius-small);
  }
}
