@import '../../styles/variables';
@import '../../styles/functions';

.login {
  min-width: 470px;
  box-sizing: border-box;
  padding-bottom: spacing(4);

  @media screen and (max-width: $tablet) {
    padding: spacing(7) spacing(2);
    width: 100%;
    max-width: 470px;
    min-width: unset;
  }

  .loginHeader {
    font-size: 40px;
    color: var(--grayscale90);
    margin-bottom: spacing(5);

    @media screen and (max-width: $tablet) {
      margin-bottom: spacing(5);
    }
  }

  .loginError {
    max-width: 470px;
    min-height: 30px;
    font-weight: 500;
    width: 100%;
    text-align: center;
    background-color: var(--red-pomegranate);
    color: var(--black);
    border-radius: var(--border-radius-small);
    display: flex;
    align-items: center;
    justify-content: center;
    padding-inline: spacing(1);
  }

  form {
    text-align: left;

    label {
      font-size: 16px;
      color: var(--grayscale90);
    }

    input {
      font-size: 16px;
      padding: 10px 20px;
      border-radius: 22.5px;
      background-color: #f9f9f9;
    }

    button {
      border-radius: 40px;
    }
  }
}

.qrcode {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: spacing(4);
  gap: spacing(2);
  align-items: center;
  font-size: var(--font-size-small);
  color: var(--grayscale90);
  pre {
    display: inline;
    padding: 0.2ch 0.5ch;
    color: initial;
    background-color: var(--grayscale10);
    border-radius: 2px;
  }
}

.loginInfoText {
  width: 470px;
}

.rightAlignedButtonContainer {
  display: flex;
  justify-content: end;
  color: var(--grayscale90);
  font-size: 16px;
  button {
    margin-left: spacing(3);
  }
}

.btnContainer {
  display: flex;
  align-items: center;
  margin-top: spacing(5);
  justify-content: space-between;
  color: var(--grayscale90);
  font-size: 16px;
}

.ssoOptions {
  text-align: center;
  margin-top: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.ssoGoogleButton {
  margin-bottom: 10px;

  [role='button'] {
    border: 1px solid var(--grayscale20) !important;
  }
}

.ssoButton {
  margin-bottom: 10px;
  width: 192px;
  background-color: rgb(255, 255, 255) !important;
  height: 43px;
  display: inline-flex;
  align-items: center;

  box-shadow: none !important;
  border: 1px solid var(--grayscale20) !important;
  border-radius: 4px !important;
  padding: 0 10px !important;

  &:hover {
    background: var(--grayscale5) !important;
  }

  > div {
    position: relative !important;
    top: 1px;
    background: none !important;
    margin-right: 2px !important;
  }
  > span {
    font-family: var(--font-family) !important;
    padding: 10px 10px 10px 0;
  }
  > svg {
    height: 18px;
    margin: 4px 6px 4px 3px !important;
  }
}
