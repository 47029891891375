@import '../../styles/variables';
@import '../../styles/functions';

.pageBar {
  display: flex;
  width: auto;
  height: 36px;
  align-items: center;
  justify-content: flex-end;
  text-align: center;
  background-color: transparent;
  padding: 0 24px;
  font-weight: 400;
  color: var(--text-grayscale70);
  box-sizing: border-box;
  position: fixed;
  z-index: 1;
  font-size: 15px;
  line-height: 15px;
  top: 120px;
  right: 0;

  &.commentsOpen {
    right: 400px;
  }

  &.noShadow {
    box-shadow: none;
  }

  .formButtonsContainer {
    button {
      margin-left: spacing(2);
    }
  }

  $slim-nav: 67px;
  $fat-nav: 103px;
  &.responsiveSlimNav {
    top: $slim-nav + 15px;

    @media (max-width: $tablet-wide) {
      top: $fat-nav + 15px;
    }
  }

  &.fatNav {
    top: $fat-nav + 15px;
  }
}
