@import 'frontend/styles/functions';

.buttonGroupWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  & > * {
    flex: 1;
    outline: 1px solid var(--grayscale15);
    // border: 2px solid var(--grayscale15);

    &:hover {
      outline: 1px solid var(--primary-color);
      z-index: 1;
    }

    &:first-child {
      border-top-left-radius: var(--border-radius-medium);
      border-bottom-left-radius: var(--border-radius-medium);
    }
    &:last-child {
      border-top-right-radius: var(--border-radius-medium);
      border-bottom-right-radius: var(--border-radius-medium);
    }
  }

  & .btnGroupFirst {
    border: none;
    border-collapse: collapse;
  }

  & .btnGroupLast {
    border: none;
    border-collapse: collapse;
  }

  & .btnGroupMiddle {
    border: none;
    border-collapse: collapse;
  }
}
