@import 'frontend/styles/forms';
@import 'frontend/styles/variables';

select.formControl {
  cursor: pointer;
  appearance: none;
  -webkit-appearance: none;
  padding: spacing(0.5) spacing(4) spacing(0.5) spacing(1.5);
  border-radius: var(--border-radius-large);
  position: relative;
  color: var(--text-grayscale90);
  border-width: 2px;
  line-height: 20px;
  &Error,
  &Error:focus,
  &Error:hover {
    border-color: var(--red-pomegranate);
  }

  &.hasLeftAdornment {
    padding-left: spacing(3.5);
  }

  &Error:focus + .errorMessage {
    display: inline-block;
  }
}

.labelWrapper {
  display: flex;
  gap: spacing(1);
  justify-content: space-between;
  align-items: center;
}

.arrow {
  position: relative;

  & .leftAdornment {
    position: absolute;
    left: spacing(1);
    top: 50%;
    transform: translateY(-50%);
    z-index: 1;
    display: flex;
    flex-direction: row;
    align-items: flex-end;
  }

  & .selectChevron {
    position: absolute;
    right: spacing(0.75);
    top: 50%;
    transform: translateY(-50%);
    pointer-events: none;
  }
}
.errorMessage {
  padding: 5px 8px 2px 8px;
  font-size: 13px;
  position: absolute;
  color: #fff;
  text-align: center;
  width: auto;
  display: none;
  top: calc(-100% + 2px);
  background-color: var(--red-pomegranate);
  box-shadow: 0 4px 8px 0 var(--shadow-color);
  z-index: 10;
  left: 5px;
  border-radius: 2px;
  white-space: nowrap;

  &:before {
    content: '';
    position: absolute;
    width: 0;
    height: 0;
    bottom: -4px;
    left: 15px;
    border-top: 4px solid var(--red-pomegranate);
    border-left: 4px solid transparent;
    border-right: 4px solid transparent;
    pointer-events: none;
  }
}
