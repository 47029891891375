@import '../../styles/variables';

:global {
  #loadOverlay {
    display: none !important;
  }
}

.mainWrapper {
  display: grid;
  grid-template-columns: 1fr auto;
  flex: 1;
}
